import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, Menu, X } from 'lucide-react';
const icon1 = require('../../assets/ai-surveillance-icon.png');
const icon2 = require('../../assets/mobile-signal-icon.png');

const Home = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeSection, setActiveSection] = useState('hero');
    const disclaimerRef = useRef<HTMLElement | null>(null);
    const sections = useRef<(HTMLElement | null)[]>([]);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observerCallback = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && entry.target.id) {
                    setActiveSection(entry.target.id);
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        sections.current.forEach((section) => {
            if (section) observer.observe(section);
        });

        return () => {
            sections.current.forEach((section) => {
                if (section) observer.unobserve(section);
            });
        };
    }, []);

    const scrollToDisclaimer = () => {
        disclaimerRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="home bg-gradient-to-br from-gray-900 to-gray-800 text-white min-h-screen">
            <nav className="fixed top-0 left-0 right-0 bg-gray-900 bg-opacity-90 backdrop-blur-md z-50">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center">
                            <Link to="/" className="text-xl font-bold text-white">Framerate</Link>
                        </div>
                        <div className="hidden md:block">
                            <div className="ml-10 flex items-baseline space-x-4">
                                <Link to="/signup"
                                      className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Sign
                                    Up</Link>
                                <Link to="/login"
                                      className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Log
                                    In</Link>
                                <Link to="/demo"
                                      className="bg-blue-500 text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-blue-600 transition duration-150 ease-in-out">Request
                                    Demo</Link>
                            </div>
                        </div>
                        <div className="md:hidden">
                            <button
                                onClick={() => setIsMenuOpen(!isMenuOpen)}
                                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                            >
                                <span className="sr-only">Open main menu</span>
                                {isMenuOpen ? <X className="block h-6 w-6"/> : <Menu className="block h-6 w-6"/>}
                            </button>
                        </div>
                    </div>
                </div>
                {isMenuOpen && (
                    <div className="md:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                            <Link to="/signup"
                                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Sign
                                Up</Link>
                            <Link to="/login"
                                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Log
                                In</Link>
                            <Link to="/demo"
                                  className="bg-blue-500 text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-blue-600 transition duration-150 ease-in-out">Request
                                Demo</Link>
                        </div>
                    </div>
                )}
            </nav>

            <div className="fixed top-16 left-0 right-0 flex justify-center z-40">
                <button
                    onClick={scrollToDisclaimer}
                    className="bg-blue-500 text-white px-6 py-2 rounded-b-lg text-sm font-medium hover:bg-blue-600 transition duration-150 ease-in-out flex items-center focus:outline-none focus:ring-0"
                >
                    Looking for our app? <ChevronDown className="ml-2 h-4 w-4"/>
                </button>
            </div>

            <div className="pt-28">
                <section id="hero" ref={(el) => (sections.current[0] = el)}
                         className="min-h-screen flex items-center justify-center text-center px-4 -mt-16">
                    <div className="max-w-4xl">
                        <h1 className="text-5xl md:text-6xl font-bold mb-6 animate-fade-in">Understand Your Space</h1>
                        <p className="text-xl md:text-2xl mb-8 animate-fade-in animation-delay-300">Tell your cameras
                            what to do</p>
                        <Link to="/demo"
                              className="inline-block bg-blue-500 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-blue-600 transition duration-300 ease-in-out transform hover:scale-105 animate-fade-in animation-delay-600">
                            Get Started
                        </Link>
                    </div>
                </section>

                <section id="features" ref={(el) => (sections.current[1] = el)} className="py-20 px-4">
                    <div className="max-w-7xl mx-auto">
                        <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">Key Features</h2>
                        <div className="grid md:grid-cols-2 gap-12">
                            <div className="bg-gray-800 rounded-lg p-8 flex flex-col items-center text-center">
                                <img src={icon1} alt="No Hardware"
                                     className="w-24 h-24 mb-6 object-contain filter invert"/>
                                <h3 className="text-2xl font-semibold mb-4">No Hardware</h3>
                                <p className="text-gray-300">Leverage your existing camera infrastructure with our
                                    cutting-edge software solution.</p>
                            </div>
                            <div className="bg-gray-800 rounded-lg p-8 flex flex-col items-center text-center">
                                <img src={icon2} alt="Go Live" className="w-24 h-24 mb-6 object-contain filter invert"/>
                                <h3 className="text-2xl font-semibold mb-4">Go Live</h3>
                                <p className="text-gray-300">Tired of Google publishing popular times on your behalf?
                                    Take control and boost customer satisfaction with meaningful insights.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="how-it-works" ref={(el) => (sections.current[2] = el)} className="py-20 px-4 bg-gray-900">
                    <div className="max-w-7xl mx-auto">
                        <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-white">How It Works</h2>
                        <div className="grid md:grid-cols-3 gap-8">
                            <div
                                className="bg-gray-800 rounded-lg p-8 flex flex-col items-center text-center transform transition duration-500 hover:scale-105">
                                <div
                                    className="w-20 h-20 bg-blue-600 rounded-full flex items-center justify-center text-2xl font-bold mb-6 text-white">1
                                </div>
                                <h3 className="text-xl font-semibold mb-4 text-white">Connect Cameras</h3>
                                <p className="text-gray-300">Easily integrate your existing camera system with our
                                    platform.</p>
                            </div>
                            <div
                                className="bg-gray-800 rounded-lg p-8 flex flex-col items-center text-center transform transition duration-500 hover:scale-105">
                                <div
                                    className="w-20 h-20 bg-blue-600 rounded-full flex items-center justify-center text-2xl font-bold mb-6 text-white">2
                                </div>
                                <h3 className="text-xl font-semibold mb-4 text-white">Define Instructions</h3>
                                <p className="text-gray-300">Customize the data points that matter most to your business
                                    and customers.</p>
                            </div>
                            <div
                                className="bg-gray-800 rounded-lg p-8 flex flex-col items-center text-center transform transition duration-500 hover:scale-105">
                                <div
                                    className="w-20 h-20 bg-blue-600 rounded-full flex items-center justify-center text-2xl font-bold mb-6 text-white">3
                                </div>
                                <h3 className="text-xl font-semibold mb-4 text-white">Gain Insights</h3>
                                <p className="text-gray-300">Access real-time analytics and actionable intelligence.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="disclaimer" ref={(el) => {
                    sections.current[3] = el;
                    disclaimerRef.current = el;
                }} className="py-20 px-4">
                    <div className="max-w-4xl mx-auto text-center">
                        <h2 className="text-3xl md:text-4xl font-bold mb-6">Framerate Live</h2>
                        <p className="text-xl mb-8">If you're interested in getting the
                            latest crowd info for your favorite places, please visit our Framerate Live app.</p>
                        <a href="https://framerate.ai"
                           className="inline-block bg-blue-500 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-blue-600 transition duration-300 ease-in-out transform hover:scale-105">
                            Go to Framerate Live
                        </a>
                    </div>
                </section>

                <footer className="bg-gray-900 py-8 px-4">
                    <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center">
                        <p className="text-gray-400 mb-4 md:mb-0">© 2024 Framerate AI. All rights reserved.</p>
                        <Link to="/demo"
                              className="bg-blue-500 text-white px-6 py-2 rounded-full text-sm font-semibold hover:bg-blue-600 transition duration-300 ease-in-out">
                            Request a Demo
                        </Link>
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default Home;