import React, {useState, useEffect, useRef} from 'react';
import { X as XIcon, Calendar as CalendarIcon, ChevronDown as ChevronDownIcon } from 'lucide-react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, PieChart, Pie, Cell } from 'recharts';
import { getActionData, ActionDataResponse, Metric } from '../../services/ActionApi';

const TIME_RANGES = [
    { label: 'Last 24 hours', value: '1d' },
    { label: 'Last 7 days', value: '7d' },
    { label: 'Last 30 days', value: '30d' },
    { label: 'Last 90 days', value: '90d' },
    { label: 'Last year', value: '1y' },
    { label: 'Custom', value: 'custom' },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

interface NumberData {
    timestamp: number;
    value: number;
}

interface TextData {
    name: string;
    timestamp: number;
    count: number;
}

interface PieData {
    name: string;
    value: number;
}

type HistoricalData = {
    number: NumberData[];
    text: {
        pie: PieData[];
        bar: TextData[];
    };
}

interface AdvancedMetricsChartProps {
    isOpen: boolean;
    onClose: () => void;
    actionId: string;
    cameraId: string;
    userId: string;
}

const AdvancedMetricsChart: React.FC<AdvancedMetricsChartProps> = ({ isOpen, onClose, actionId, cameraId, userId }) => {
    const [historicalData, setHistoricalData] = useState<HistoricalData>({
        number: [],
        text: { pie: [], bar: [] }
    });
    const [metricType, setMetricType] = useState<'NUMBER' | 'TEXT'>('NUMBER');
    const [chartType, setChartType] = useState<'line' | 'pie' | 'bar'>('line');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [timeRange, setTimeRange] = useState(TIME_RANGES[2]); // Default to 30 days
    const [customStartDate, setCustomStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]);
    const [customEndDate, setCustomEndDate] = useState(new Date().toISOString().split('T')[0]);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const datePickerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
                setShowDatePicker(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [datePickerRef]);

    useEffect(() => {
        if (isOpen) {
            fetchData();
        }
    }, [isOpen, timeRange, customStartDate, customEndDate]);

    const fetchData = async () => {
        if (!userId || !actionId || !cameraId) return;

        setIsLoading(true);
        setError(null);
        try {
            let startDate, endDate;
            if (timeRange.value === 'custom') {
                startDate = new Date(customStartDate);
                endDate = new Date(customEndDate);
            } else {
                endDate = new Date();
                startDate = new Date();
                const days = parseInt(timeRange.value);
                startDate.setDate(startDate.getDate() - days);
            }

            const response: ActionDataResponse = await getActionData(actionId, userId, cameraId, startDate.toISOString(), endDate.toISOString());

            if (response && response.metrics && response.metrics.length > 0) {
                setMetricType(response.metrics[0].type as 'NUMBER' | 'TEXT');

                if (response.metrics[0].type === 'NUMBER') {
                    const formattedData = response.metrics.map((metric: Metric) => ({
                        timestamp: new Date(metric.timestamp).getTime(),
                        value: parseFloat(metric.data)
                    })).sort((a, b) => a.timestamp - b.timestamp);
                    setHistoricalData({ number: formattedData, text: { pie: [], bar: [] } });
                    setChartType('line');
                } else {
                    const textData = response.metrics.map((metric: Metric) => ({
                        name: metric.data,
                        timestamp: new Date(metric.timestamp).getTime()
                    }));

                    const pieData = textData.reduce((acc, curr) => {
                        acc[curr.name] = (acc[curr.name] || 0) + 1;
                        return acc;
                    }, {} as Record<string, number>);

                    const pieChartData = Object.entries(pieData).map(([name, value]) => ({ name, value }));

                    const barChartData = Object.entries(pieData).map(([name, count]) => ({
                        name,
                        count,
                        timestamp: textData.find(item => item.name === name)?.timestamp || 0
                    })).sort((a, b) => a.timestamp - b.timestamp);

                    setHistoricalData({
                        number: [],
                        text: {
                            pie: pieChartData,
                            bar: barChartData
                        }
                    });
                    setChartType('pie');
                }
            } else {
                setHistoricalData({ number: [], text: { pie: [], bar: [] } });
                setError('No historical data available for this action.');
            }
        } catch (err) {
            console.error('Error fetching action data:', err);
            setError('Failed to fetch data. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleTimeRangeChange = (range: typeof TIME_RANGES[0]) => {
        setTimeRange(range);
        if (range.value === 'custom') {
            setShowDatePicker(true);
        } else {
            setShowDatePicker(false);
        }
    };

    const formatXAxis = (tickItem: number) => {
        const date = new Date(tickItem);
        return date.toLocaleString([], {
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const renderChart = () => {
        const chartProps = {
            width: '100%',
            height: 400,
            margin: { top: 5, right: 30, left: 20, bottom: 5 },
        };

        if (metricType === 'NUMBER') {
            return (
                <ResponsiveContainer {...chartProps}>
                    <LineChart data={historicalData.number}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#4B5563" />
                        <XAxis dataKey="timestamp" tickFormatter={formatXAxis} stroke="#9CA3AF" />
                        <YAxis stroke="#9CA3AF" />
                        <Tooltip
                            labelFormatter={formatXAxis}
                            contentStyle={{ backgroundColor: '#1F2937', border: 'none', borderRadius: '4px' }}
                        />
                        <Legend />
                        <Line type="monotone" dataKey="value" stroke="#3B82F6" activeDot={{ r: 8 }} />
                    </LineChart>
                </ResponsiveContainer>
            );
        } else if (chartType === 'pie') {
            return (
                <ResponsiveContainer {...chartProps}>
                    <PieChart>
                        <Pie
                            data={historicalData.text.pie}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                        >
                            {historicalData.text.pie.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip contentStyle={{ backgroundColor: '#1F2937', border: 'none', borderRadius: '4px' }} />
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>
            );
        } else {
            return (
                <ResponsiveContainer {...chartProps}>
                    <BarChart data={historicalData.text.bar}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#4B5563" />
                        <XAxis dataKey="timestamp" tickFormatter={formatXAxis} stroke="#9CA3AF" />
                        <YAxis stroke="#9CA3AF" />
                        <Tooltip
                            labelFormatter={formatXAxis}
                            contentStyle={{ backgroundColor: '#1F2937', border: 'none', borderRadius: '4px' }}
                        />
                        <Legend />
                        <Bar dataKey="count" fill="#3B82F6" />
                    </BarChart>
                </ResponsiveContainer>
            );
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 overflow-y-auto h-full w-full z-50">
            <div className="relative top-20 mx-auto p-5 border border-gray-700 w-11/12 max-w-4xl shadow-lg rounded-md bg-gray-800 text-white">
                <div className="mt-3">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg leading-6 font-medium">Historical Data Analysis</h3>
                        <button
                            onClick={onClose}
                            className="text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                        >
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>

                    <div className="mt-2">
                        <div className="flex justify-between items-center mb-4">
                            <div className="relative" ref={datePickerRef}>
                                <button
                                    type="button"
                                    className="inline-flex justify-center items-center w-full rounded-md border border-gray-600 shadow-sm px-4 py-2 bg-gray-700 text-sm font-medium text-gray-200 hover:bg-gray-600 focus:outline-none focus:ring-0"
                                    onClick={() => setShowDatePicker(!showDatePicker)}
                                >
                                    <CalendarIcon className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    <span>{timeRange.label}</span>
                                    <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </button>

                                {showDatePicker && (
                                    <div className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                            {TIME_RANGES.map((range) => (
                                                <button
                                                    key={range.value}
                                                    onClick={() => {
                                                        handleTimeRangeChange(range);
                                                        setShowDatePicker(false);
                                                    }}
                                                    className="block w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-600 hover:text-white focus:outline-none focus:ring-0"
                                                    role="menuitem"
                                                >
                                                    {range.label}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>

                            {timeRange.value === 'custom' && (
                                <div className="flex space-x-2">
                                    <input
                                        type="date"
                                        value={customStartDate}
                                        onChange={(e) => setCustomStartDate(e.target.value)}
                                        className="bg-gray-700 border border-gray-600 rounded p-2 text-white focus:outline-none focus:ring-0"
                                    />
                                    <input
                                        type="date"
                                        value={customEndDate}
                                        onChange={(e) => setCustomEndDate(e.target.value)}
                                        className="bg-gray-700 border border-gray-600 rounded p-2 text-white focus:outline-none focus:ring-0"
                                    />
                                </div>
                            )}
                        </div>

                        {metricType === 'TEXT' && (
                            <div className="flex space-x-2 mb-4">
                                <button
                                    onClick={() => setChartType('pie')}
                                    className={`px-3 py-1 rounded ${chartType === 'pie' ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300'} focus:outline-none focus:ring-0`}
                                >
                                    Pie Chart
                                </button>
                                <button
                                    onClick={() => setChartType('bar')}
                                    className={`px-3 py-1 rounded ${chartType === 'bar' ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300'} focus:outline-none focus:ring-0`}
                                >
                                    Bar Chart
                                </button>
                            </div>
                        )}

                        {isLoading ? (
                            <div className="flex justify-center items-center h-64">
                                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
                            </div>
                        ) : error ? (
                            <div className="text-red-500 text-center">{error}</div>
                        ) : (
                            renderChart()
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdvancedMetricsChart;
