import React, { useState } from 'react';
import { Shield, Video, Server, HardDrive, ChevronLeft, X } from 'lucide-react';

interface CameraRegistrationModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (cameraData: any) => void;
}

enum CameraType {
    IP = 'IP',
    NVR = 'NVR',
    DVR = 'DVR'
}

const CameraRegistrationModal: React.FC<CameraRegistrationModalProps> = ({ isOpen, onClose, onSubmit }) => {
    const [step, setStep] = useState(1);
    const [cameraType, setCameraType] = useState<CameraType | null>(null);
    const [cameraData, setCameraData] = useState({
        type: null as CameraType | null,
        name: '',
        ip: '',
        port: '',
        username: '',
        password: '',
        channels: 1,
        rtspUrl: '',
    });

    const handleNext = () => setStep(step + 1);
    const handleBack = () => setStep(step - 1);

    const handleCameraTypeSelection = (type: CameraType) => {
        setCameraType(type);
        setCameraData({ ...cameraData, type });
        handleNext();
    };

    const handleSubmit = () => {
        onSubmit(cameraData);
        onClose();
        setStep(1);
        setCameraData({
            type: null,
            name: '',
            ip: '',
            port: '',
            username: '',
            password: '',
            channels: 1,
            rtspUrl: '',
        });
    };

    const renderConnectionGuide = () => {
        switch (cameraType) {
            case CameraType.IP:
                return (
                    <div className="space-y-4">
                        <h3 className="text-lg font-semibold">Connecting your IP Camera</h3>
                        <ol className="list-decimal list-inside space-y-2">
                            <li>Ensure your camera is powered on and connected to the same network as this device.</li>
                            <li>Your camera's IP address is: <strong>{cameraData.ip}</strong></li>
                            <li>To access the camera's web interface, open a browser and go to: <strong>http://{cameraData.ip}:{cameraData.port}</strong></li>
                            <li>Log in using the username: <strong>{cameraData.username}</strong> and the password you provided.</li>
                            <li>Your camera's RTSP stream URL is: <strong>{cameraData.rtspUrl}</strong></li>
                            <li>You can use this URL in compatible video players or surveillance software to view the camera's feed.</li>
                        </ol>
                    </div>
                );
            case CameraType.NVR:
                return (
                    <div className="space-y-4">
                        <h3 className="text-lg font-semibold">Connecting your NVR</h3>
                        <ol className="list-decimal list-inside space-y-2">
                            <li>Ensure your NVR is powered on and connected to the same network as this device.</li>
                            <li>Your NVR's IP address is: <strong>{cameraData.ip}</strong></li>
                            <li>To access the NVR's web interface, open a browser and go to: <strong>http://{cameraData.ip}:{cameraData.port}</strong></li>
                            <li>Log in using the username: <strong>{cameraData.username}</strong> and the password you provided.</li>
                            <li>Your NVR has <strong>{cameraData.channels}</strong> channels. Each channel corresponds to a connected camera.</li>
                            <li>To access individual camera streams, you may need to use URLs in the format: <strong>rtsp://{cameraData.username}:password@{cameraData.ip}:{cameraData.port}/cam/realmonitor?channel=1&subtype=0</strong> (replace 'password' with your actual password, and adjust the channel number as needed)</li>
                        </ol>
                    </div>
                );
            case CameraType.DVR:
                return (
                    <div className="space-y-4">
                        <h3 className="text-lg font-semibold">Connecting your DVR</h3>
                        <ol className="list-decimal list-inside space-y-2">
                            <li>Ensure your DVR is powered on and connected to the same network as this device.</li>
                            <li>Your DVR's IP address is: <strong>{cameraData.ip}</strong></li>
                            <li>To access the DVR's web interface, open a browser and go to: <strong>http://{cameraData.ip}:{cameraData.port}</strong></li>
                            <li>Log in using the username: <strong>{cameraData.username}</strong> and the password you provided.</li>
                            <li>Your DVR has <strong>{cameraData.channels}</strong> channels. Each channel corresponds to a connected camera.</li>
                            <li>The process for accessing individual camera streams may vary depending on your DVR model. Consult your DVR's manual for specific instructions on accessing RTSP streams if needed.</li>
                        </ol>
                    </div>
                );
            default:
                return <p>Please select a camera type to see connection instructions.</p>;
        }
    };

    const renderConfirmationSummary = () => {
        return (
            <div className="space-y-4">
                <h3 className="text-lg font-semibold">Camera Details</h3>
                <table className="w-full">
                    <tbody>
                    <tr><td className="font-medium">Type:</td><td>{cameraType}</td></tr>
                    <tr><td className="font-medium">Name:</td><td>{cameraData.name}</td></tr>
                    <tr><td className="font-medium">IP Address:</td><td>{cameraData.ip}</td></tr>
                    <tr><td className="font-medium">Port:</td><td>{cameraData.port}</td></tr>
                    <tr><td className="font-medium">Username:</td><td>{cameraData.username}</td></tr>
                    {cameraType === CameraType.IP && (
                        <tr><td className="font-medium">RTSP URL:</td><td>{cameraData.rtspUrl}</td></tr>
                    )}
                    {(cameraType === CameraType.NVR || cameraType === CameraType.DVR) && (
                        <tr><td className="font-medium">Channels:</td><td>{cameraData.channels}</td></tr>
                    )}
                    </tbody>
                </table>
                <p className="text-sm text-gray-300">Please review the details above. If everything is correct, click 'Submit' to add this camera. If you need to make changes, click 'Back' to return to the previous steps.</p>
            </div>
        );
    };

    if (!isOpen) return null;

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <div className="space-y-6">
                        <h2 className="text-2xl font-bold">Privacy and Security</h2>
                        <div className="space-y-4">
                            <Shield className="w-16 h-16 text-blue-500 mx-auto" />
                            <p className="text-gray-300">
                                Framerate is committed to protecting your privacy and ensuring the security of your data.
                                We want to assure you that:
                            </p>
                            <ul className="list-disc list-inside space-y-2 text-gray-300">
                                <li>Framerate will never collect or use camera data for purposes not directly related to the services we provide to you.</li>
                                <li>All data in our system is encrypted and secure, accessible only to the account holder by default.</li>
                                <li>Your privacy and data security are our top priorities.</li>
                            </ul>
                            <p className="text-gray-300">
                                By proceeding, you acknowledge that you have read and understood our commitment to your privacy and data security.
                            </p>
                        </div>
                        <button onClick={handleNext} className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300">I Understand, Continue</button>
                    </div>
                );
            case 2:
                return (
                    <div className="space-y-6">
                        <h2 className="text-2xl font-bold">Select Camera Type</h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            {[
                                { type: CameraType.IP, icon: Video, label: "IP Camera" },
                                { type: CameraType.NVR, icon: Server, label: "NVR" },
                                { type: CameraType.DVR, icon: HardDrive, label: "DVR" },
                            ].map(({ type, icon: Icon, label }) => (
                                <button
                                    key={type}
                                    onClick={() => handleCameraTypeSelection(type)}
                                    className="flex flex-col items-center justify-center p-6 bg-gray-700 rounded-lg hover:bg-gray-600 transition duration-300"
                                >
                                    <Icon className="w-12 h-12 mb-2" />
                                    <span>{label}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className="space-y-6">
                        <h2 className="text-2xl font-bold">{cameraType} Camera Details</h2>
                        <form onSubmit={(e) => { e.preventDefault(); handleNext(); }} className="space-y-4">
                            <input
                                type="text"
                                placeholder="Camera Name"
                                value={cameraData.name}
                                onChange={(e) => setCameraData({...cameraData, name: e.target.value})}
                                required
                                className="w-full p-2 bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <input
                                type="text"
                                placeholder="IP Address"
                                value={cameraData.ip}
                                onChange={(e) => setCameraData({...cameraData, ip: e.target.value})}
                                required
                                className="w-full p-2 bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <input
                                type="number"
                                placeholder="Port"
                                value={cameraData.port}
                                onChange={(e) => setCameraData({...cameraData, port: e.target.value})}
                                required
                                className="w-full p-2 bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <input
                                type="text"
                                placeholder="Username"
                                value={cameraData.username}
                                onChange={(e) => setCameraData({...cameraData, username: e.target.value})}
                                required
                                className="w-full p-2 bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <input
                                type="password"
                                placeholder="Password"
                                value={cameraData.password}
                                onChange={(e) => setCameraData({...cameraData, password: e.target.value})}
                                required
                                className="w-full p-2 bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            {cameraType === CameraType.IP && (
                                <input
                                    type="text"
                                    placeholder="RTSP URL"
                                    value={cameraData.rtspUrl}
                                    onChange={(e) => setCameraData({...cameraData, rtspUrl: e.target.value})}
                                    required
                                    className="w-full p-2 bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            )}
                            {(cameraType === CameraType.NVR || cameraType === CameraType.DVR) && (
                                <input
                                    type="number"
                                    placeholder="Number of Channels"
                                    value={cameraData.channels}
                                    onChange={(e) => setCameraData({...cameraData, channels: parseInt(e.target.value)})}
                                    required
                                    className="w-full p-2 bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            )}
                            <button type="submit" className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300">Next</button>
                        </form>
                    </div>
                );
            case 4:
                return (
                    <div className="space-y-6">
                        <h2 className="text-2xl font-bold">Connection Guide</h2>
                        {renderConnectionGuide()}
                        <button onClick={handleNext} className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300">Next</button>
                    </div>
                );
            case 5:
                return (
                    <div className="space-y-6">
                        <h2 className="text-2xl font-bold">Confirm Details</h2>
                        {renderConfirmationSummary()}
                        <div className="flex space-x-4">
                            <button onClick={handleBack} className="flex-1 bg-gray-600 text-white py-2 px-4 rounded hover:bg-gray-700 transition duration-300">Back</button>
                            <button onClick={handleSubmit} className="flex-1 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300">Submit</button>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-gray-800 p-8 rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center mb-6">
                    <button onClick={handleBack} className="text-gray-400 hover:text-white" disabled={step === 1}>
                        <ChevronLeft className="w-6 h-6" />
                    </button>
                    <button onClick={onClose} className="text-gray-400 hover:text-white">
                        <X className="w-6 h-6" />
                    </button>
                </div>
                {renderStep()}
            </div>
        </div>
    );
};

export default CameraRegistrationModal;