import React, {useCallback, useEffect, useRef, useState} from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { ArrowLeft, Plus, MoreVertical, RefreshCw, LogOut, Info } from 'lucide-react';
import { getSingleFrame, Frame, getLatestSnapshot } from '../../services/ImageApi';
import { AuthUser, signOut, getCurrentUser } from "@aws-amplify/auth";
import ActionModal from "../../components/ActionModal";
import { addAction } from "../../services/ActionApi";
import AdvancedMetricsChart from "../../components/AdvancedMetricsChart/AdvancedMetricsChart";
import Tooltip from "../../components/Toottip/Tooltip";

const CameraView: React.FC = () => {
    const { cameraId, timestamp } = useParams<{ cameraId: string, timestamp: string }>();
    const [frame, setFrame] = useState<Frame | null>(null);
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [isMetricsModalOpen, setIsMetricsModalOpen] = useState(false);
    const [user, setUser] = useState<AuthUser | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [refreshCooldown, setRefreshCooldown] = useState(0);
    const [selectedAction, setSelectedAction] = useState<string | null>(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const infoIconRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const loadFrame = useCallback(async (userId: string, cameraId: string, timestamp: string) => {
        try {
            setIsLoading(true);
            const frameData = await getSingleFrame(userId, cameraId, timestamp);
            if (frameData) {
                setFrame(frameData);
            }
        } catch (error) {
            console.error('Error loading frame:', error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const refreshFrame = useCallback(async () => {
        if (!user || !cameraId || refreshCooldown > 0) return;

        try {
            setIsRefreshing(true);
            const latestSnapshot = await getLatestSnapshot(user.userId, cameraId);
            if (latestSnapshot) {
                setFrame(latestSnapshot);
                // Update the URL with the new timestamp
                navigate(`/dashboard/${cameraId}/${latestSnapshot.time}`, { replace: true });
            }
            // Start the cooldown
            setRefreshCooldown(60);
        } catch (error) {
            console.error('Error refreshing frame:', error);
        } finally {
            setIsRefreshing(false);
        }
    }, [user, cameraId, navigate, refreshCooldown]);

    useEffect(() => {
        const initializeUserAndLoadFrame = async () => {
            try {
                const currentUser = await getCurrentUser();
                setUser(currentUser);
                if (cameraId && timestamp) {
                    await loadFrame(currentUser.userId, cameraId, timestamp);
                }
            } catch (error) {
                console.error('Error initializing user or loading frame:', error);
                setIsLoading(false);
            }
        };

        initializeUserAndLoadFrame();
    }, [cameraId, timestamp, loadFrame]);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (refreshCooldown > 0) {
            interval = setInterval(() => {
                setRefreshCooldown((prevCooldown) => prevCooldown - 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [refreshCooldown]);

    const handleLogout = async () => {
        try {
            await signOut();
            navigate('/login');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    const handleActionClick = (actionId: string) => {
        setSelectedAction(actionId);
        setIsMetricsModalOpen(true);
    };

    const handleAddAction = async (actionText: string, actionType: 'NUMBER' | 'TEXT') => {
        if (frame && user && cameraId) {
            try {
                const newAction = await addAction(
                    actionText,
                    user.userId,
                    cameraId,
                    actionType,
                );

                setFrame(prevFrame => ({
                    ...prevFrame!,
                    actions: [...prevFrame!.actions, newAction]
                }));
            } catch (error) {
                console.error('Error adding action:', error);
            }
        }
    };

    if (isLoading) {
        return (
            <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 flex justify-center items-center">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (!frame) {
        return <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 flex justify-center items-center text-white">No frame data available.</div>;
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
            <nav className="bg-gray-800 shadow-lg">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16">
                        <div className="flex items-center">
                            <Link to="/dashboard" className="text-xl font-bold text-white">Framerate</Link>
                        </div>
                        <div className="flex items-center space-x-4">
                            <Link to="/live" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium focus:outline-none">Live</Link>
                            <Link to="/profile" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium focus:outline-none">Profile</Link>
                            <button onClick={handleLogout} className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium flex items-center focus:outline-none focus:ring-0">
                                <LogOut className="w-4 h-4 mr-2" /> Sign Out
                            </button>
                        </div>
                    </div>
                </div>
            </nav>

            <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <div className="mb-6">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center space-x-4">
                            <Link to="/dashboard"
                                  className="text-blue-500 hover:text-blue-600 focus:outline-none focus:ring-0">
                                <ArrowLeft className="w-6 h-6"/>
                            </Link>
                            <h1 className="text-2xl font-bold">{frame.label}</h1>
                            <span className="text-gray-400">{frame.time}</span>
                        </div>
                        <button
                            onClick={refreshFrame}
                            className={`flex items-center bg-blue-500 text-white px-4 py-2 rounded ${isRefreshing || refreshCooldown > 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'} focus:outline-none focus:ring-0`}
                            //disabled={isRefreshing || refreshCooldown > 0}
                            disabled={true}
                        >
                            <RefreshCw className={`w-4 h-4 mr-2 ${isRefreshing ? 'animate-spin' : ''}`}/>
                            {isRefreshing ? 'Refreshing...' : refreshCooldown > 0 ? `Refresh (${refreshCooldown}s)` : 'Refresh'}
                        </button>
                    </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <div className="bg-gray-800 rounded-lg overflow-hidden shadow-lg">
                        <img src={frame.imageUrl} alt={frame.label} className="w-full h-full object-contain"/>
                    </div>
                    <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden">
                        <div className="p-6">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-xl font-semibold">Actions</h2>
                                <button onClick={() => setIsActionModalOpen(true)}
                                        className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-0">
                                    <Plus className="w-4 h-4"/>
                                </button>
                            </div>
                            <div className="space-y-2 max-h-[calc(100vh-300px)] overflow-y-auto">
                                {frame.actions.map((action) => (
                                    <div
                                        key={action.id}
                                        className={`flex justify-between items-center p-3 rounded cursor-pointer transition-colors duration-150 ${selectedAction === action.id ? 'bg-blue-600' : 'bg-gray-700 hover:bg-gray-600'} focus:outline-none focus:ring-0`}
                                        onClick={() => handleActionClick(action.id)}
                                    >
                                        <div>
                                            <span className="font-medium text-blue-300">{action.category}</span>
                                            <span className="ml-2 text-gray-300">{action.description}</span>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <span>{action.result.value}</span>
                                            <MoreVertical className="w-4 h-4 text-gray-400"/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 mt-10">
                    <div className="bg-gray-800 rounded-lg overflow-hidden shadow-lg p-6">
                        <div className="flex items-center mb-4">
                            <h2 className="text-xl font-semibold mr-2">Insights</h2>
                            <div
                                ref={infoIconRef}
                                onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                                className="relative cursor-help"
                            >
                                <Info className="w-5 h-5 text-gray-400 hover:text-gray-300" />
                            </div>
                        </div>
                        {/* Add your AI business suggestions content here */}
                        <p className="text-gray-300">AI-generated business insights will appear here.</p>
                    </div>
                </div>

                {showTooltip && (
                    <Tooltip
                        content="AI-generated business suggestions based on data. These insights are for informational purposes and should be independently verified"
                        parentRef={infoIconRef}
                    />
                )}
            </main>

            <ActionModal
                isOpen={isActionModalOpen}
                onClose={() => setIsActionModalOpen(false)}
                onSubmit={handleAddAction}
            />

            {user && cameraId && selectedAction && (
                <AdvancedMetricsChart
                    isOpen={isMetricsModalOpen}
                    onClose={() => setIsMetricsModalOpen(false)}
                    actionId={selectedAction}
                    cameraId={cameraId}
                    userId={user.userId}
                />
            )}
        </div>
    );
};

export default CameraView;