import React, { useState, useRef, useEffect } from 'react';

interface ActionModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (action: string, type: 'NUMBER' | 'TEXT') => void;
}

const ActionModal: React.FC<ActionModalProps> = ({ isOpen, onClose, onSubmit }) => {
    const [action, setAction] = useState<string>('');
    const [actionType, setActionType] = useState<'NUMBER' | 'TEXT'>('TEXT');
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen, onClose]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(action, actionType);
        setAction('');
        setActionType('TEXT');
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div ref={modalRef} className="bg-gray-800 rounded-lg p-6 w-full max-w-md">
                <h2 className="text-2xl font-bold mb-4 text-white">Add New Action</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="flex justify-center">
                        <div className="inline-flex rounded-md shadow-sm" role="group">
                            <button
                                type="button"
                                onClick={() => setActionType('TEXT')}
                                className={`px-4 py-2 text-sm font-medium rounded-l-lg ${
                                    actionType === 'TEXT'
                                        ? 'bg-blue-500 text-white'
                                        : 'bg-gray-700 text-gray-300 hover:bg-gray-600 hover:text-white'
                                }`}
                            >
                                Text
                            </button>
                            <button
                                type="button"
                                onClick={() => setActionType('NUMBER')}
                                className={`px-4 py-2 text-sm font-medium rounded-r-lg ${
                                    actionType === 'NUMBER'
                                        ? 'bg-blue-500 text-white'
                                        : 'bg-gray-700 text-gray-300 hover:bg-gray-600 hover:text-white'
                                }`}
                            >
                                Number
                            </button>
                        </div>
                    </div>
                    <textarea
                        value={action}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setAction(e.target.value)}
                        placeholder={actionType === 'NUMBER' ?
                            "Enter an action that produces a number, i.e. How many cars are on the road..." :
                            "Enter an action that produces a text output, i.e. Which piece of equipment is most occupied..."}
                        rows={4}
                        className="w-full p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-500 transition-colors duration-150"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-150"
                        >
                            Add Action
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ActionModal;