import React from 'react';
import './App.scss';
import AppRoutes from "./routes";
import { Amplify } from 'aws-amplify';
import {AuthProvider} from "./contexts/AuthContext";

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: 'us-east-1_gtkgFmjSF',
            userPoolClientId: '3rko1hh57g474d6o57fronl48c',
        }
    }
});

function App() {
  return (
      <AuthProvider>
          <div className="App">
            <AppRoutes/>
          </div>
      </AuthProvider>
  );
}

export default App;
