import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./pages/Dashboard";
import CameraView from "./pages/CameraView";
import Live from "./pages/Live";
import Profile from "./pages/Profile";
import AdvancedMetricsChart from "./components/AdvancedMetricsChart/AdvancedMetricsChart";

const AppRoutes = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route element={<ProtectedRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/live" element={<Live />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/dashboard/:cameraId/:timestamp" element={<CameraView />} />
                {/* Add other protected routes here if needed */}
            </Route>
        </Routes>
    </BrowserRouter>
);

export default AppRoutes;