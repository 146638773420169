import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { ChevronLeft } from 'lucide-react';

const SignUp: React.FC = () => {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const { isAuthenticated, isLoading, user } = useAuth();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Here you would typically send the email to your backend
        console.log('Email submitted:', email);
        setSubmitted(true);
    };

    if (isLoading) {
        return <div className="flex justify-center items-center h-screen">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
        </div>;
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <Link to="/" className="flex items-center text-white hover:text-blue-300 transition duration-150 ease-in-out mb-6">
                    <ChevronLeft className="h-5 w-5 mr-1" />
                    Back to Home
                </Link>
                <h2 className="text-center text-3xl font-extrabold text-white">
                    Framerate AI Beta
                </h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-gray-800 py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    {isAuthenticated && (
                        <div className="mb-6 p-4 bg-blue-900 rounded-md text-white">
                            <p>You are currently logged in as {user?.username || user?.email}.</p>
                            <p>You can join the waitlist with a different email below.</p>
                        </div>
                    )}
                    <p className="text-white mb-6">
                        We're sorry, but Framerate AI is currently in closed beta. Only select customers can access the software at this time.
                    </p>
                    {!submitted ? (
                        <>
                            <p className="text-white mb-6">
                                Interested in joining our waitlist? Please enter your email below:
                            </p>
                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div>
                                    <label htmlFor="email" className="sr-only">
                                        Email address
                                    </label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-gray-700 text-white"
                                        placeholder="Enter your email"
                                    />
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                    >
                                        Join Waitlist
                                    </button>
                                </div>
                            </form>
                        </>
                    ) : (
                        <p className="text-green-400 font-medium">
                            Thank you for your interest! We'll notify you when access becomes available.
                        </p>
                    )}
                    {isAuthenticated && (
                        <div className="mt-6 text-center">
                            <Link to="/dashboard" className="font-medium text-blue-400 hover:text-blue-300">
                                Go to Dashboard
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SignUp;