import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LogOut, Plus, Loader } from 'lucide-react';
import { fetchImages, Frame, getDashboardFrames, ImageData } from '../../services/ImageApi';
import { AuthUser, signOut } from "@aws-amplify/auth";
import { getCurrentUser } from "aws-amplify/auth";
import CameraRegistrationModal from "../../components/CameraRegistrationModal";
import { useAuth } from "../../contexts/AuthContext";

const Dashboard: React.FC = () => {
    const [frames, setFrames] = useState<Frame[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState<AuthUser | null>(null);
    const navigate = useNavigate();
    const { checkAuth } = useAuth();

    const loadFrames = useCallback(async (userId: string) => {
        try {
            setIsLoading(true);
            const imageArrays: ImageData[] = await fetchImages(userId);
            const frameArray: Frame[] = getDashboardFrames(imageArrays);
            setFrames(frameArray);
        } catch (error) {
            console.error('Error loading frames:', error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        const initializeUser = async () => {
            try {
                const currentUser = await getCurrentUser();
                setUser(currentUser);
                await loadFrames(currentUser.userId);
            } catch (error) {
                console.error('Error initializing user:', error);
                setIsLoading(false);
                // Handle error (e.g., redirect to login page)
            }
        };

        initializeUser();
    }, [loadFrames]);

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const handleCameraRegistration = (cameraData: any) => {
        console.log('Registering new camera:', cameraData);
        // Implement the logic to handle new camera registration
        handleCloseModal();
        if (user) {
            loadFrames(user.userId);
        }
    };

    const handleSignOut = async () => {
        try {
            await signOut()
            await checkAuth()
            navigate('/login');
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
            <nav className="bg-gray-800 shadow-lg">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16">
                        <div className="flex items-center">
                            <Link to="/" className="text-xl font-bold text-white">Framerate</Link>
                        </div>
                        <div className="flex items-center">
                            <Link to="/live" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Live</Link>
                            <Link to="/profile" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Profile</Link>
                            <button onClick={handleSignOut} className="ml-4 flex items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                                <LogOut className="w-4 h-4 mr-2" /> Sign Out
                            </button>
                        </div>
                    </div>
                </div>
            </nav>

            <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <div className="px-4 py-6 sm:px-0">
                    <h1 className="text-3xl font-bold mb-6">Welcome, {user?.username}</h1>

                    <section className="bg-gray-800 rounded-lg shadow-xl p-6">
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-2xl font-semibold">Connected Cameras</h2>
                            <button onClick={handleOpenModal} className="flex items-center bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out">
                                <Plus className="w-4 h-4 mr-2" /> Add Camera
                            </button>
                        </div>
                        {isLoading ? (
                            <div className="flex justify-center items-center h-64">
                                <Loader className="w-12 h-12 animate-spin text-blue-500" />
                            </div>
                        ) : (
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                                {frames.map((frame) => (
                                    <Link to={`/dashboard/${frame.id}/${frame.time}`} key={frame.id} className="block bg-gray-700 rounded-lg overflow-hidden shadow-md hover:shadow-lg transition duration-150 ease-in-out">
                                        <img src={frame.imageUrl} alt={frame.label} className="w-full h-48 object-cover" />
                                        <div className="p-4">
                                            <span className="text-lg font-medium">{frame.label}</span>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        )}
                    </section>
                </div>
            </main>

            <CameraRegistrationModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onSubmit={handleCameraRegistration}
            />
        </div>
    );
};

export default Dashboard;