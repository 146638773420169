// Updated interfaces
export interface Frame {
    id: string;
    imageUrl: string;
    label: string;
    time: string;
    actions: Action[];
}

export interface ImageData {
    id: string;
    imageUrl: string; // Changed from imageBytes to imageUrl
    title: string;
    timestamp: string;
    actions: Action[];
}

export interface Action {
    id: string;
    description: string;
    category: string;
    result: {
        type: string;
        value: string;
    };
}

/**
 * Get image from backend API using pre-signed URL
 */
export const getSingleFrame = async (
    userId: string,
    frameId: string,
    time: string,
): Promise<Frame | undefined> => {
    try {
        const response = await fetch(
            `https://dos.framerate.ai/user/${userId}/image/${frameId}/${time}`,
        );
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const imageData: ImageData = await response.json();

        return {
            id: frameId,
            imageUrl: imageData.imageUrl, // Use the pre-signed URL directly
            label: imageData.title,
            time: new Date(parseInt(time)).toLocaleString(),
            actions: imageData.actions,
        };
    } catch (error) {
        console.error("Error fetching image:", error);
    }
};

export const getDashboardFrames = (imageData: ImageData[]): Frame[] => {
    return imageData.map((item) => {
        const { id, imageUrl, title, timestamp, actions } = item;

        return {
            id: id,
            imageUrl: imageUrl, // Use the pre-signed URL directly
            label: title,
            time: timestamp,
            actions: actions,
        };
    });
};

export const fetchImages = async (userId: string): Promise<ImageData[]> => {
    const response = await fetch(
        `https://dos.framerate.ai/user/${userId}/images`,
    );
    if (!response.ok) {
        throw new Error("Network response was not ok");
    }

    return response.json();
};

export const getLatestSnapshot = async (userId: string, cameraId: string): Promise<Frame | null> => {
    try {
        const response = await fetch("http://127.0.0.1:5000/capture", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                frameGroupId: cameraId,
                userId: userId,
            }),
        });
        if (!response.ok) {
            throw new Error('Failed to fetch latest snapshot');
        }

        const imageData: ImageData = await response.json();

        return {
            id: cameraId,
            imageUrl: imageData.imageUrl, // Use the pre-signed URL directly
            label: imageData.title,
            time: imageData.timestamp,
            actions: imageData.actions,
        };
    } catch (error) {
        console.error('Error fetching latest snapshot:', error);
        return null;
    }
};