import React, {useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";
interface TooltipProps {
    content: string;
    parentRef: React.RefObject<HTMLElement>;
}

const Tooltip: React.FC<TooltipProps> = ({ content, parentRef }) => {
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const tooltipRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const updatePosition = () => {
            if (parentRef.current && tooltipRef.current) {
                const parentRect = parentRef.current.getBoundingClientRect();
                const tooltipRect = tooltipRef.current.getBoundingClientRect();

                let left = parentRect.right + 10;
                let top = parentRect.top + parentRect.height / 2 - tooltipRect.height / 2;

                // Check if tooltip goes beyond right edge of screen
                if (left + tooltipRect.width > window.innerWidth) {
                    left = parentRect.left - tooltipRect.width - 10;
                }

                // Ensure tooltip doesn't go above or below screen
                top = Math.max(10, Math.min(top, window.innerHeight - tooltipRect.height - 10));

                setTooltipPosition({ top, left });
            }
        };

        updatePosition();
        window.addEventListener('resize', updatePosition);
        window.addEventListener('scroll', updatePosition);

        return () => {
            window.removeEventListener('resize', updatePosition);
            window.removeEventListener('scroll', updatePosition);
        };
    }, [parentRef]);

    return createPortal(
        <div
            ref={tooltipRef}
            className="fixed z-50 px-3 py-2 bg-gray-700 text-white text-sm rounded-lg shadow-lg transition-opacity duration-300 whitespace-normal max-w-xs"
            style={{
                top: `${tooltipPosition.top}px`,
                left: `${tooltipPosition.left}px`,
                opacity: parentRef.current ? 1 : 0,
            }}
        >
            {content}
        </div>,
        document.body
    );
};

export default Tooltip