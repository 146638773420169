import { Action } from "./ImageApi";

export interface Metric {
    type: string;
    data: string;
    timestamp: string;
}

export interface ActionDataResponse {
    metrics: Metric[];
}


export const addAction = async (
    description: string,
    userId: string,
    frameGroupId: string,
    category: string,
): Promise<Action> => {
    const response = await fetch("https://dos.framerate.ai/action", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            userId: userId,
            frameGroupId: frameGroupId,
            description: description,
            category: category,
        }),
    });

    if (!response.ok) {
        throw new Error("Network response was not ok");
    }

    return response.json();
};

export const getActionData = async (
    actionId: string,
    userId: string,
    cameraId: string,
    startDate: string,
    endDate: string,
): Promise<ActionDataResponse> => {
    const response = await fetch("https://dos.framerate.ai/action/" + actionId, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            userId: userId,
            frameGroupId: cameraId
        }),
    });

    if (!response.ok) {
        throw new Error("Network response was not ok");
    }

    return response.json();
}
