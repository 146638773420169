import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LogOut, ToggleLeft, ToggleRight, AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import { AuthUser, signOut, getCurrentUser } from "@aws-amplify/auth";
import { Frame, fetchImages, Action, getDashboardFrames } from '../../services/ImageApi';

interface ActionWithFrame extends Action {
    frameId: string;
    frameName: string;
}

const Live: React.FC = () => {
    const [user, setUser] = useState<AuthUser | null>(null);
    const [actions, setActions] = useState<ActionWithFrame[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [confirmationModal, setConfirmationModal] = useState<{ isOpen: boolean; action: ActionWithFrame | null }>({
        isOpen: false,
        action: null
    });
    const navigate = useNavigate();
    const { checkAuth } = useAuth();

    useEffect(() => {
        const initializeUser = async () => {
            try {
                const currentUser = await getCurrentUser();
                setUser(currentUser);
                const imageDataArrays = await fetchImages(currentUser.userId);
                const frameArrays = getDashboardFrames(imageDataArrays);
                const allActions = frameArrays.flatMap(frame =>
                    frame.actions.map(action => ({
                        ...action,
                        frameId: frame.id,
                        frameName: frame.label
                    }))
                );
                setActions(allActions);
            } catch (error) {
                console.error('Error initializing user:', error);
            } finally {
                setIsLoading(false);
            }
        };

        initializeUser();
    }, []);

    const handleSignOut = async () => {
        try {
            await signOut();
            await checkAuth();
            navigate('/login');
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };

    const handleToggleVisibility = async (action: ActionWithFrame) => {
        if (action.result.value === 'private') {
            setConfirmationModal({ isOpen: true, action });
        } else {
            await updateActionVisibility(action, 'private');
        }
    };

    const updateActionVisibility = async (action: ActionWithFrame, visibility: 'public' | 'private') => {
        try {
            // TODO: Implement API call to update action visibility
            // const updatedAction = await updateActionVisibilityAPI(action.id, visibility);

            // For now, we'll just update the state locally
            setActions(prevActions =>
                prevActions.map(a =>
                    a.id === action.id ? { ...a, result: { ...a.result, value: visibility } } : a
                )
            );
        } catch (error) {
            console.error('Error updating action visibility:', error);
        }
    };

    const handleConfirmEnable = async () => {
        if (confirmationModal.action) {
            await updateActionVisibility(confirmationModal.action, 'public');
            setConfirmationModal({ isOpen: false, action: null });
        }
    };

    if (isLoading) {
        return (
            <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 flex justify-center items-center">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
            <nav className="bg-gray-800 shadow-lg">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16">
                        <div className="flex items-center">
                            <Link to="/" className="text-xl font-bold text-white">Framerate</Link>
                        </div>
                        <div className="flex items-center">
                            <Link to="/dashboard" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Dashboard</Link>
                            <Link to="/profile" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Profile</Link>
                            <button onClick={handleSignOut} className="ml-4 flex items-center text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                                <LogOut className="w-4 h-4 mr-2" /> Sign Out
                            </button>
                        </div>
                    </div>
                </div>
            </nav>

            <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <div className="px-4 py-6 sm:px-0">
                    <h1 className="text-3xl font-bold mb-6">Live Metrics</h1>

                    <div className="bg-gray-800 rounded-lg shadow-xl p-6">
                        <h2 className="text-2xl font-semibold mb-6">Actions</h2>

                        <div className="space-y-4">
                            {actions.map((action) => (
                                <div key={action.id} className="flex justify-between items-center bg-gray-700 p-4 rounded-lg">
                                    <div>
                                        <span className="text-sm text-blue-400">[{action.frameName}]</span>
                                        <span className="ml-2 font-medium">{action.description}</span>
                                    </div>
                                    <button
                                        onClick={() => handleToggleVisibility(action)}
                                        className={`flex items-center px-3 py-1 rounded transition-colors duration-200 ${
                                            action.result.value === 'public'
                                                ? 'bg-green-600 hover:bg-green-700'
                                                : 'bg-gray-600 hover:bg-blue-600'
                                        }`}
                                    >
                                        {action.result.value === 'public' ? (
                                            <>
                                                <ToggleRight className="w-4 h-4 mr-2" />
                                                Disable
                                            </>
                                        ) : (
                                            <>
                                                <ToggleLeft className="w-4 h-4 mr-2" />
                                                Enable
                                            </>
                                        )}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </main>

            {confirmationModal.isOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <AlertCircle className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                            Enable Public Visibility
                                        </h3>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Are you sure you want to make this action publicly visible? This will allow it to be seen on the consumer-facing app.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={handleConfirmEnable}
                                >
                                    Enable
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => setConfirmationModal({ isOpen: false, action: null })}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Live;